export const environment = {
  name: 'development',
  version: 'VERSION',
  production: false,
  apiGatewayUrl: 'http://localhost:3050',
  apidocsUrl: 'http://leafhill-gateway-dev-apidocs.s3-website-eu-west-1.amazonaws.com/',
  integrationS3Buckets: {
    leafhill: 'leafhill-gateway-dev-issues',
    bmh: 'leafhill-gateway-dev-bmh'
  },
  domain: 'dev.leafhill.net',
  sentry: {
    dsn: null, // Disables Sentry
    environment: 'development',
    release: 'VERSION'
  }
};
