
import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {SnackBarService} from "../snackbar/snackbar.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the access token from the local storage.
    const access_token = localStorage.getItem('LHP.access_token');
    const token_type = localStorage.getItem('LHP.token_type');
    // Clone the request to add the new header.
    const apiReq = req.clone({headers: req.headers.set('Authorization', `${token_type} ${access_token}`)});
    // Pass on the cloned request instead of the original request.
    return next.handle(apiReq).pipe(catchError((error: any) => this.snackBarService.error(error)));
  }
}
